@import "rsuite/dist/rsuite.css";
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input::placeholder,
select::placeholder,
textarea::placeholder {
    color: rgba(102, 112, 133, 0.6) !important;
}
.flex-space-between-flex-end{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.flex-space-between-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.marketingModal .ant-upload.ant-upload-drag .ant-upload {
    padding: 6px 22px 6px 9px;
    width: 100%;
}

.marketingModal .ant-upload.ant-upload-drag .ant-upload-drag-container > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.ant-modal-content {
    border-radius: 11px 11px 0 0 !important;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    color: #6B7280 !important;
    font-size: 12px !important;
    line-height: 20px !important;
}

.buttonsSlider.slick-slider {
    max-width: 1350px;
    width: 100%;
    display: flex;
    height: 32px;
}

.slick-list {
    width: 100%; /* Required: set width to 100% */
    /*overflow-y: auto !important;*/
    /*overflow-x: hidden !important;*/
}

.slick-slide {
    margin: 0 4px;
}

.swiper-horizontal {
    max-width: 100%;
    /*width: 94%;*/
    display: flex;
    height: 32px;
}

.swiper-slide {
    width: fit-content !important;
}

.swiper-button-prev,
.swiper-button-next {

    width: 60px;
    height: 36px;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 5;
}

.swiper, .swiper-container {
    margin-right: initial !important;
    margin-left: initial !important;
}

.swiper-button-prev {
    left: -26px;
    background: linear-gradient(90deg, #FFFFFF 60.19%, rgba(255, 255, 255, 0.77) 100%);
}

.swiper-button-next {
    right: -26px;
    background: linear-gradient(270deg, #FFFFFF 60.19%, rgba(255, 255, 255, 0.77) 100%);
}

input[type="checkbox"]:checked.eye-checkbox {
    background-color: #292929;
}

.offerSortFilterBtns {
    display: flex;
    justify-content: space-between;
    min-width: 180px;
}

.offerForm {
    position: relative;
}

.offerForm .ant-row {
    width: 100%;
}

.offerForm .ant-form-item {
    margin-bottom: 0;
    width: 100%;
}

.offerForm .ant-select.ant-select-in-form-item {
    margin-bottom: 0;
}

.offerForm .ant-input {
    border: none;
    border-bottom: 1px solid #EDEFF1;
    border-radius: 0;
    outline: none;
    height: 31px;
    padding: 0 6px 0 6px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    line-height: 16px;
}

.offerForm .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 0;
    outline: none;
    padding: 4px 9px 4px 0;
}

.offerForm .ant-select-multiple .ant-select-selection-placeholder {
    left: 0;
}

.offerForm .ant-select-selection-overflow {
    margin-left: -4px;
}

.offerForm .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
}

.offerForm .ant-form-item-explain-error {
    position: absolute;
    left: 0;
}

.offerForm .ant-select-multiple .ant-select-selection-item-content {
    margin-right: 4px;
}

.offerForm .ant-select-multiple .ant-select-selection-item {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #1A73E8;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    height: 30px;
    background-color: #FFFFFF;
}

/*.offerForm .ant-select-selection-overflow-item-suffix{*/
/*  opacity: 0 !important;*/
/*}*/
.offerForm .ant-select-multiple .ant-select-selector::after {
    content: '';
    background-image: url("/src/img/icons/plus-circle.png");
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 0;
}

.offerForm .ant-select-selection-overflow-item:first-child {
    margin-left: 40px;
}

.propertyModal .ant-select-selector {
    height: 40px !important;
}

.resizable-div {
    position: relative;
    min-height: 25px;
    max-height: 200px;
    border-top: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
    display: flex;

}

.resizable-div:nth-of-type(odd) {
    background-color: #F9FAFB;
}

.resizable-div:nth-of-type(odd) {
    background-color: #F9FAFB;
}

.resizable-div:nth-of-type(odd) .handle {
    background-color: #F9FAFB;
}
.resizable-div:nth-of-type(even) {
    background-color: #FFF;
}
.handle {
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #fff;
    cursor: row-resize;
}

.handle-top {
    top: -2px;
}

.handle-bottom {
    bottom: -2px;
}

.MuiDivider-root {
    display: none;
}

#react-select-3-listbox {
    background-color: #fff;
    z-index: 999;
}

.css-b62m3t-container {
    width: 100%;
}

.css-1p3m7a8-multiValue {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #1A73E8;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 8px !important;
    height: 30px;
    background-color: #FFFFFF !important;
}

.css-wsp0cs-MultiValueGeneric {
    color: #1A73E8 !important;
}

.css-12a83d4-MultiValueRemove:hover {
    background-color: transparent !important;
}

.offerList .ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    min-width: 180px;
    padding: 8px 6px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 30%;
    margin-left: -90px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}


.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 52.5%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.gm-style .gm-style-iw-c {
    border-radius: 8px;
    background: #101828;
    color: #FFF;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    padding: 0;
    min-height: 64px;
}

.gm-style .gm-style-iw-c > div {
    display: flex !important;
    flex-direction: column !important;
}

.gm-style .gm-style-iw-d {
    background: #101828;
    overflow: initial !important;
}

.gm-ui-hover-effect > span {
    background-color: #fff;
    opacity: 1;
}

.gm-ui-hover-effect {
    opacity: 1;
}

.gm-style .gm-style-iw-tc::after {
    background: #101828;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #101828 !important;
    color: #fff !important;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

}

.leaflet-popup-content-wrapper > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leaflet-right {
    display: none !important;
}

.addressTooltip {
    position: absolute;
    top: 44px;
    right: 10px;
    z-index: 10000;
    cursor: pointer;
}
.addressTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 198px;
    left: 30.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.brokerageButtonsTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 298px;
    left: 30.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.addressButtonsTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 198px;
    left: 46.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.activitiesTabsTooltip {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 30px;
}
.activitiesTabsTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 198px;
    left: 105.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.activitiesModeTooltip {
    cursor: pointer;
    margin-top: -2px;
   display: flex !important;
    justify-content: center;
    align-items: center;
}
.activitiesModeTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 198px;
    left: 89.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.activitiesContactIconsModeTooltip {
    cursor: pointer;
    margin-top: -2px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 17px;
}
.activitiesContactIconsModeTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 200px;
    min-width: 200px;
    left: 495.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.activitiesContactIconsModeTooltip.tooltip .tooltiptext::after {
    left: 6.5%;
}
.activitiesContactIconsModeTooltip button{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .activitiesCheckboxTooltip {
     cursor: pointer;
     margin-top: 0;
     margin-left: 0;
 }
.activitiesCheckboxTooltip.tooltip{
    position: absolute;
    display: flex!important;
    align-items: center;
}
.activitiesCheckboxTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    left: 103.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.activitiesCheckboxTooltip.tooltip .tooltiptext::after {
    left: 3.5%;
}
.activitiesGridForm{
    position: relative;
}
.activitiesGridForm .odd-row{
    background-color: #F9FAFB;
    border-top: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
}
.activitiesGridForm .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 43px;
}
.activitiesGridForm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.activitiesGridForm .ant-picker,
.activitiesGridForm textarea.ant-input{
    height: 44px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background:  #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.ant-switch-checked{
    background-color: #0707FA !important;
}.activitiesGridForm .ant-select-single .ant-select-selector .ant-select-selection-search{
    top: 3px;
 }
 .activitiesGridForm .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
     line-height: 40px;
 }
 .activitiesGridForm .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
 .activitiesGridForm .ant-picker.ant-picker-disabled,
 .activitiesGridForm .ant-input[disabled],
 .activitiesGridForm .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
 .activitiesGridForm .ant-select-selection-placeholder{
     background: #F9FAFB;
     box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
     color: #667085;
 }
 .activitiesGridForm .ant-picker-input > input[disabled]{
     color: #667085;
 }
 .mapSelect{
     border-radius: 8px;
     background:#FFF;
     height: 32px;
 }
 .mapSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
     height: 32px;
 }
.mapSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    color: #111827;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}
.mapSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 30px !important;
}
.addressMapSelect{
    border-radius: 8px;
    background:#FFF;
    height: 36px;
}
.addressMapSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 36px;
}
.addressMapSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    color: #111827;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
}
.addressMapSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 30px !important;
}
.mapInput{
    width: 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
}
.rs-btn-xs{
    font-size: 14px;
}
.rs-calendar-table-header-cell-content{
    font-size: 14px;
}
.rs-picker-toggle{
    font-size: 16px;
}
.rs-picker-toggle-textbox{
    padding: 0 20px 0 10px;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color: rgba(0, 0, 0, 0.85);
}
.rs-btn-link{
    color: #1755E7;
}
.rs-btn-primary{
    background-color: #1755E7;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    background-color: #1755E7;
}
.notificationItem .rs-picker-toggle-wrapper{
    visibility: hidden;
    height: 0;
}
.notificationsDatePicker.rs-picker-toggle-wrapper > div{
    height: 42px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.notificationsDatePicker.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
    top: 10px;
}
.rs-picker-menu{
    z-index: 100;
}
.defaultNotification .rs-picker-toggle-wrapper{
    visibility: hidden;
    height: 0;
}
.sigCanvas{
    border: 1px solid #000;
}
.ant-input-number{
    width: 100% !important;
}
.attrCodes .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 44px;
}
.attrCodes .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 42px !important;
}
.attrCodes .ant-input-number-input{
    height: 44px;
}
.createContactForm{
    width: 100%;
}
.createContactForm .ant-form-item-label > label{
    color: #344054;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

}
.createContactForm .ant-form-vertical .ant-form-item-label{
    padding-bottom: 6px;
}
.createContactForm .ant-input{
    min-height: 44px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background:  #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.createContactForm .ant-form-item-control-input-content{
    height: 100%;
}
.createContactForm .ant-form-item{
    margin-bottom: 16px;
    width: 100%;
}
.createContactForm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 44px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background:  #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.createContactForm .ant-select-single .ant-select-selector .ant-select-selection-item{
    line-height: 44px;
}
.attributeCodesTable .ant-input-number-disabled{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
}
.brokerageReqsSelect .ant-select{
    color: #FFF !important;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;
    text-transform: uppercase;
}
.brokerageReqsSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: transparent;
}
.brokerageReqsSelect .ant-select-single .ant-select-open .ant-select-selection-item{
    color: #FFF !important;
}
.brokerageReqsSelect span{
    color: #FFF !important;
}
.brokerageOfferTooltip{
    cursor: pointer;
    margin-top: 0;
    margin-left: 0;
}
.brokerageOfferTooltip.tooltip{
    position: absolute;
    display: flex!important;
    align-items: center;
}
.brokerageOfferTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    right: -20px!important;
    left: initial;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    bottom: 5px;
}
.brokerageOfferTooltip.tooltip .tooltiptext::after {
    left: 92.5%;
}
.ant-popover{
    width: 250px;
}
.ant-popover-message {
    padding: 4px 22px 12px !important;
}
.ant-popover-buttons{
    display: flex;
    justify-content: center;
}
.ant-popover-message-title{
    text-align: center;
}
.brokerageStatusSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 32px;
}
.brokerageStatusSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 32px!important;
}
.brokerageStatusSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 32px;
}
.brokerageStatusSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after{
    line-height: 32px;
}
.brokerageStatusSelect.ant-select-arrow{
    margin-top: -5px;
}
.reportAgentSelects .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.reportAgentSelects .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    color: #161616;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.reportAgentSelects .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    color: #161616;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.reportAgentSelects .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 44px;
}
.reportAgentSelects .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 44px;
}
.reportAgentSelects .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 41px !important;
}
.addNewAgentRank .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.addNewAgentRank .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    color: #161616;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.addNewAgentRank .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    color: #161616;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.addNewAgentRank .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 44px;
}
.addNewAgentRank .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 44px;
}
.addNewAgentRank .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 41px !important;
}
.activitiesSwitchTooltip {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 10px;
}
.activitiesSwitchTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 198px;
    left: 40.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.notificationsSelect{
    width: 100%;
}

.reqCreateFormSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 100%;
}
.reqCreateFormSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 100%;
}
.reqCreateFormSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 44px !important;
}
.reqCreateFormSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 44px;
    width: 100%;
    line-height: 44px !important;
}
.reqCreateFormSelect.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: #667085 ;
    opacity: 0.6;
    font-size: 16px;
}
.reqCreateFormSelect.ant-select-single .ant-select-selector .ant-select-selection-item{
    color: #000;
    opacity: 1;
}

.changeKindSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 30px;
    width: 100%;
}
.changeKindSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 30px;
    width: 100%;
}
.changeKindSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 30px !important;
}
.changeKindSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 30px;
    width: 100%;
    line-height: 30px !important;
}
.changeKindSelect.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: #667085 ;
    opacity: 0.6;
    font-size: 16px;
}
.changeKindSelect.ant-select-single .ant-select-selector .ant-select-selection-item{
    color: #000;
    opacity: 1;
}

.ant-input::placeholder{
    color: #667085 ;
    opacity: 0.6;
}
.notificationsSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 100%;
}
.notificationsSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    min-height: 44px;
    width: 100%;
}
.notificationsSelect label{
    color: #344054;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
}
.notificationsSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 42px;
    padding-right: 45px !important;
}

.notificationsSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 42px;
}
.notificationsSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 42px !important;
}
.accessSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 44px;
    width: 100%;
}
.accessSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    min-height: 44px;
    width: 100%;
}
.accessSelect label{
    color: #344054;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
}
.accessSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 42px;
}

.accessSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 42px;
}
.accessSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 42px !important;
}
.caption{
    stroke: #000;
    stroke-width: 1px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: -10px;
}
.blue-text {
    stroke: #fff;
    stroke-width: 1px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: -10px;
}
.svg-map .area-cover{
    stroke-width: 1px;
}




.dispoziceCheckboxes .ant-checkbox-wrapper + .ant-checkbox-wrapper{
    margin-left: 0;
    margin-bottom: 8px;
}
.dispoziceCheckboxes .ant-checkbox-wrapper {
    margin-left: 0;
    margin-bottom: 8px;
    min-width: 90px;
}

.birthdayContactPicker .ant-picker{
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #344054;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    height: 44px;
}

.birthdayContactPicker .ant-picker input{
    border: none;
    box-shadow: none;
    background-color: transparent;
}
.dispoziceCheckboxes .rs-checkbox-group > div{
    display: flex;
    gap: 110px;
}
.dispoziceCheckboxes > div{
    display: flex;
    gap: 110px;
}


.currSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: none;
    background: #FFF;
    box-shadow: none;
    height: 44px;
    width: 80px;
    outline: none;
}
.currSelect.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    border: none;
    background: none;
    box-shadow: none;
    height: 44px;
    width: 80px;
    outline: none;
}
.currSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 44px !important;
    outline: none;
}
.comboReqFormBox input{
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}

 .propsWithoutReqButtonsTooltip {
     cursor: pointer;
 }
.propsWithoutReqButtonsTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -389.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.react-tel-input .form-control{
    border-radius: 8px !important;
    border: 1px solid #D0D5DD !important;
    background: #FFF !important;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
    height: 44px !important;
    width: 100% !important;
    color: #344054 !important;
    font-family: Inter, sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.react-tel-input .flag-dropdown{
    border-radius: 8px 0 0 8px !important;
    background-color: #fff !important;
}
.react-tel-input .country-list .country{
    font-family: Inter, sans-serif !important;
    font-size: 16px !important;
}
.react-tel-input .country-list{
    width: 325px !important;
}

.reqsSearchInputTooltip {
    cursor: pointer;
}
.reqsSearchInputTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    white-space: initial;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -391.5%;
    bottom: 140%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.reqsSearchInput .ant-select-arrow{
    font-size: 16px;
    background-color: #fff;
    top: 37%;
    height: 24px;
}


.activitiesSearchInputTooltip {
    cursor: pointer;
}
.activitiesSearchInputTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -1250.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    bottom: 175%;
    font-weight: 500;
    line-height: 130%;
}
.activitiesSearchInput .ant-select-arrow{
    font-size: 16px;
}
.activitiesSearchInputTooltip.tooltip .tooltiptext:after{
    left: 97%;
    top: 99%;
}

.notificationsSearchInputTooltip {
    cursor: pointer;
}
.notificationsSearchInputTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -389.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    bottom: 175%;
    font-weight: 500;
    line-height: 130%;
}
.notificationsSearchInput .ant-select-arrow{
    font-size: 16px;
}
.filtersSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 40px !important;
}
.filtersSelect.ant-select-arrow{
    top: 53%;
}
.macos-datepicker {
    width: 280px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: absolute;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.nav-button {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0 10px;
    color: #007aff;
}

/*.Cal__Header__root{*/
/*    display: none !important;*/
/*}*/
.Cal__Container__root{
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25) !important;
}
.Cal__Header__root{
    min-height: 60px !important;
    padding: 10px !important;
    background-color: #2D2D2D !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;

}

.Cal__Header__dateWrapper.Cal__Header__day{
    font-size: 24px !important;
}
.Cal__Weekdays__day{
    color: #2D2D2D !important;
    background-color: #fff!important;
}
.Cal__Weekdays__root{
    border-bottom: 1px solid #2D2D2D !important;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection{
    background-color: #1755E7!important;
}
.Cal__Day__root.Cal__Day__today{
    color: #1755E7!important;
}

.FaqPanel .ant-collapse-header{
    flex-direction: row-reverse;
    padding: 16px 20px !important;
}

.FaqPanel .ant-collapse-header-text{
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.FaqPanel .ant-collapse-content-box{
    color: #667085;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.FaqPanel .ant-collapse-content-box p{
    margin-bottom: 0;
}

.FaqPanel .anticon{
    font-size: 14px !important;
}

.reqsSearchInput .ant-select-selection-item {
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: initial;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}

/* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Opera, macOS) */
.reqsSearchInput .ant-select-selection-item::-webkit-scrollbar {
    display: none; /* Completely hides scrollbar */
    width: 0;
    height: 0;
}

/* Remove scrollbar arrows (buttons) */
/*.reqsSearchInput .ant-select-selection-item::-webkit-scrollbar-button {*/
/*    width: 0;*/
/*    height: 0;*/
/*    display: none; !* This hides the left and right arrows *!*/
/*}*/

/* Custom scrollbar thumb */
.reqsSearchInput .ant-select-selection-item::-webkit-scrollbar-thumb {
    background-color: #d4d4d4; /* Thumb color */
    border-radius: 8px; /* Rounded scrollbar */
}

/* Optional: Add some margin or padding to the scrollbar */
.reqsSearchInput .ant-select-selection-item::-webkit-scrollbar-track {
    /*background-color: transparent; !* Scrollbar track (background) *!*/
    margin: 4px 0; /* Create space between scrollbar and edges */
}
.propContactsTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -1250.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    bottom: 138%;
    font-weight: 500;
    line-height: 130%;
}
.rrSurfacesTooltip .ant-select-arrow{
    font-size: 16px;
}
.rrSurfacesTooltip.tooltip .tooltiptext:after{
    left: 47%;
    top: 99%;
}
.rrSurfacesTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -285.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    bottom: 138%;
    font-weight: 500;
    line-height: 130%;
}
.offerLangTooltip .ant-select-arrow{
    font-size: 16px;
}
.offerLangTooltip.tooltip .tooltiptext:after{
    left: 96%;
    top: 99%;
}
.offerLangTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 308px;
    min-width: 308px;
    left: -285.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    bottom: 138%;
    font-weight: 500;
    line-height: 130%;
}
.propContactsTooltip .ant-select-arrow{
    font-size: 16px;
}
.propContactsTooltip.tooltip .tooltiptext:after{
    left: 97%;
    top: 99%;
}
.propertiesMapTooltip {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 0px;
    width: 100px;
}
.propertiesMapTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 398px;
    left: 115.5%;
    bottom: -20%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.propertiesMapTooltip.tooltip .tooltiptext:after{
    right: 100%;
    top: 36%;
    left: initial;
    transform: rotate(90deg);
}
.reqStatusTooltip {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 0px;
    width: 400px;
}
.reqStatusTooltip.tooltip .tooltiptext {
    border-radius: 8px;
    font-size: 12px;
    max-width: 398px;
    min-width: 300px;
    left: 19.5%;
    z-index: 1000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}
.reqStatusTooltip.tooltip .tooltiptext:after{
    right: 94%;
    top: 99%;
    left: initial;
}

.editPicturesUploader .ant-upload.ant-upload-select-picture-card{
    max-width: 376px;
    min-width: 376px;
    width: 100%;
    height: 264px;
    border-radius: 4px;
    margin: 0;
    position: relative;
}
.tableViewUploader .ant-upload.ant-upload-select-picture-card{
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 264px;
    border-radius: 8px;
    margin: 0;
    position: relative;
}
/*.createPicturesUploader .ant-upload.ant-upload-select-picture-card{*/
/*    width: 100%;*/
/*    height: 200px;*/
/*    border-radius: 4px;*/
/*    margin: 0;*/
/*    position: relative;*/
/*}*/

.createPicturesUploader img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.createPicturesUploader .image-container{
    height: 100%;
    width: 100%;
}
.image-container{
    height: 100%;
}
.ant-message{
    z-index: 10000;
}
.custom-popconfirm{
    left: 15px !important;
}
.custom-popconfirm-icon{
    left: 15px !important;
}
.custom-popconfirm-icon.ant-popover-placement-top .ant-popover-arrow{
    left: 6% !important;
}
.media-uploader .ant-upload-list{
    display: flex;
}
.pdf-page-break {
    page-break-before: always;
    break-before: always;
    height: 0; /* Ensures no visual space in the web view */
}

/* Default red border */
.red-checkbox .ant-checkbox-inner {
    border-color: red !important;
}

/* Checked state - red background and border */
.red-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red !important;
}

/* Hover effect - red border */
.red-checkbox:hover .ant-checkbox-inner {
    border-color: red !important;
}

/* Hover effect when checked */
.red-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: red;
    border-color: red !important;
}

/* Focus effect (outline when clicking) */
.red-checkbox .ant-checkbox-wrapper:focus-within .ant-checkbox-inner {
    border-color: red !important;
    box-shadow: 0 0 4px rgba(255, 0, 0, 0.5);
}
.red-checkbox .ant-checkbox-checked::after{
    border-color: red !important;
}

/* Red tick when selected */
.red-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white; /* Keep the tick white for contrast */
}

.media-uploader .ant-upload-list {
    display: flex;
    flex-wrap: wrap-reverse;
}

.media-uploader .ant-upload-list-picture-card-container {
    width: calc(25% - 10px); /* 4 items per row, minus gap */
    box-sizing: border-box;
}
.media-uploader .ant-upload.ant-upload-select-picture-card{
    width: 100%; /* 4 items per row, minus gap */
    box-sizing: border-box;
    margin-bottom: 30px;
    background-color: #FFF;
}
.picturesCheckbox .ant-checkbox-inner{
    border: 1px solid #1890FF;
}
.avatar-uploader .ant-upload.ant-upload-disabled{
    cursor: pointer !important;
}
@media (max-width: 1130px) {
    .rs-picker-menu{
        left: 0!important;
    }
}


@media (max-width: 1000px) {
    .rs-picker-daterange-calendar-group{
        height: fit-content;
        max-width: 100%;
        min-width: 100%;
    }
    .rs-picker-menu .rs-calendar{
        min-width: 100%;
    }
}
@media (max-width: 840px) {
    .marketingTable .ant-table-container{
        overflow-y: hidden;
        overflow-x: scroll;
    }
}

@media (max-width: 768px) {
    .dispoziceCheckboxes > div{
        gap: 0;
    }
}